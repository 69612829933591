import React from 'react';
import { useDispatch } from 'react-redux';
import { closeModalAction } from '../../../actions/modal';
import { submitAdhaarOtp } from '../../../actions/users';
import Form from '../../../common/Form';
import Input from '../../../common/Form/Input';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
const formName = 'verifyAadharNumber';

export const VerifyAadharForm = ({ aadhar_number, closeModalAfterSuccess = true }) => {
    console.log('aadhar_number:55555555', aadhar_number)
    const dispatch = useDispatch();
    const defaultValues = {};
    const { formButtonLoading } = useSelector((state) => state.loading)


    const onSubmit = (formValues) => {
        if (aadhar_number) {
            dispatch(submitAdhaarOtp(formValues?.otp, formName, aadhar_number, closeModalAfterSuccess));
        }
    };

    const methods = useForm({ defaultValues, mode: 'all' });

    return (
        <Form
            methods={methods}
            onSubmit={onSubmit}
            submitButtonText={'Verify'}
            onCancel={() => dispatch(closeModalAction())}
            showSubmit
            submitContainerClassName='flex gap-2 justify-end py-4 px-4'
            isLoading={formButtonLoading[formName]}
            onEnterPressSubmit
        >
            <Input
                label='OTP'
                required
                name='otp'
                type={'number'}
                maxLength={6}
                validate={(otp) => {
                    if (otp === '') return true;
                    return otp.length === 6 || 'Invalid OTP'
                }}
            />
            <div className='flex justify-center max-w-max m-auto '>
                <span className='w-full italic text-sm text-scogogray m-auto'>
                    <span className='text-bold text-black'>Note:</span> OTP has been sent to your adhaar registered mobile number
                </span>
            </div>
        </Form>
    );
};
